import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRestaurantList() {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)
  const display = ref('list')

  // Options
  const typeOptions = [
    { value: '', text: 'ALL' },
    { value: 'Daylife', text: 'Daylife' },
    { value: 'Nightlife', text: 'Nightlife' },
    { value: 'Staywithus', text: 'Stay with us' },
  ]
  const statusOptions = [
    { value: '', text: 'ALL' },
    { value: 'Activate', text: 'Activate' },
    { value: 'Inactivate', text: 'Inactivate' },
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'hamburger', label: '', class: 'text-center' },
    { key: 'position', label: '#', class: 'text-center' },
    { key: 'image', label: 'image' },
    { key: 'information.name', label: 'restuarant name' },
    { key: 'category', label: 'category' },
    { key: 'status' },
    { key: 'createdTime', label: 'last updated' },
    { key: 'actions', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalCards = ref(0)
  const restaurants = ref([])
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const typeQuery = ref('')
  const statusQuery = ref('')
  const sortBy = ref('position')
  const isSortDirDesc = ref(false)
  const draggableOptions = ref({
    animation: 400,
  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCards.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusQuery, typeQuery], () => {
    refetchData()
  })

  const fetchCards = (_, callback) => {
    store
      .dispatch('app-restaurant/getRestaurants', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        ascend: !isSortDirDesc.value,
        status: statusQuery.value,
        category: typeQuery.value,
      })
      .then(response => {
        const { data, total } = response.data;
        callback(data);
        restaurants.value = data;
        totalCards.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching restaurants',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteCard = async (id) => {
    try {
      const response = await store.dispatch('app-restaurant/deleteRestaurant', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error delete restaurant',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const swapPosition = (oldPosition, newPosition) => {
    return store.dispatch('app-restaurant/swapPosition', { oldPosition, newPosition })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCardStatusVariant = status => {
    if (status === 'Activate') return 'success'
    if (status === 'Inactivate') return 'secondary'
    return 'primary'
  }

  return {
    toast,

    // options
    typeOptions,
    statusOptions,

    // method
    swapPosition,
    fetchCards,
    deleteCard,

    // data
    display,
    restaurants,
    tableColumns,
    perPage,
    currentPage,
    totalCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    typeQuery,
    statusQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    draggableOptions,
    
    // UI
    resolveCardStatusVariant,
    refetchData,
  }
}
