<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col sm="12">
          <b-row class="mb-1">
            <div class="d-flex justify-content-end w-full">
              <b-button-group size="md">
                <b-button 
                  :variant="`${display === 'list' ? 'outline-primary' : 'outline-secondary'}`"
                  :pressed="display === 'list'"
                  @click="() => display = 'list'"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  :variant="`${display === 'grid' ? 'outline-primary' : 'outline-secondary'}`"
                  :pressed="display === 'grid'"
                  @click="() => display = 'grid'"
                >
                  <feather-icon
                    icon="GridIcon"
                    size="16"
                  />
                </b-button>
              </b-button-group>
            </div>
          </b-row>
        </b-col>
        <b-col sm="12">
          <b-row>
            <div class="d-flex justify-content-between gap-2 w-full filter">
              <div class="d-flex gap-2 w-full flex-wrap max-w-800px">
                <div class="d-flex align-items-center flex-grow">
                  <p class="search-label">Search</p>
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block"
                    placeholder="Search by keyword"
                  />
                </div>
                  <div class="d-flex align-items-center flex-grow">
                    <p class="search-label">Category</p>
                    <b-form-select
                      v-model="typeQuery"
                      :options="typeOptions"
                    />
                  </div>
                  <div class="d-flex align-items-center flex-grow">
                    <p class="search-label">Status</p>
                    <b-form-select
                      v-model="statusQuery"
                      :options="statusOptions"
                    />
                  </div>
              </div>
              <b-button
                class="text-nowrap"
                variant="primary"
                :to="{ name: 'restaurant-create' }"
              >
                <span class="text-nowrap">Add Restaurant</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="16"
                  class="add-restaurant-icon"
                />
              </b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      :hidden="display === 'grid'"
    >
      <b-table
        ref="refListTable"
        class="position-relative mb-0"
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
        :isBusy="isBusy"
        :items="fetchCards"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        v-draggabletable="draggableOptions"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(hamburger)>
          <feather-icon
            icon="MenuIcon"
            size="24"
          />
        </template>

        <!-- Column: Status -->
        <template #cell(image)="data">
          <b-img
            :src="data.item.information.logoUrl"
            width="80"
            height="40"
          />
        </template>

        <!-- Column: Status -->
        <template #cell(category)="data">
          <span>{{ data.item.setting.category.join(', ') }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveCardStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <div class="action-button">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: 'restaurant-edit', params: { id: data.item.id } }">
                <span class="align-middle ml-50">View &amp; Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDelete(data.item.id)">
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
    </b-card>

    <!-- Pagination -->
    <div
      class="mt-2 mx-2"
      :hidden="display === 'grid'"
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing
            </span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap"> of {{ dataMeta.of }} entries </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCards"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <div class="grid" :hidden="display === 'list'">
      <div
        class="card card-custom"
        v-for="(restaurant, index) of restaurants"
        :key="index"
      >    
        <b-badge
          pill
          :variant="`light-${resolveCardStatusVariant(restaurant.status)}`"
          class="text-capitalize card-badge"
        >
          {{ restaurant.status }}
        </b-badge>  
        <b-img :src="restaurant.information.logoUrl" height="180" class="w-full" />
        <div>
          <div class="card-title-wrapper">
            <span class="card-title">{{ restaurant.information.name }}</span>
            <span>Restaurants</span>
          </div>
          <span class="card-body-2">
            {{ restaurant.information.description }}
          </span>
          <div class="card-button-wrapper">
            <b-button
              class="text-nowrap"
              variant="outline-secondary"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
              />
            </b-button>
            <b-button
              class="text-nowrap flex-grow"
              variant="outline-secondary"
            >
              <span class="text-nowrap">Edit</span>
              <feather-icon
                icon="EditIcon"
                size="16"
                class="add-restaurant-icon"
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButtonGroup, BFormInput, BButton, BImg, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownDivider, BDropdownItem, BFormSelect, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useRestaurantList from './useRestaurantList'
import restaurantStoreModule from '../restaurantStoreModule'
import { draggabletable } from "@/libs/vue-draggable.js";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BPagination,
    BImg,

    vSelect,
  },
  data() {
    return {
      isBusy: false
    }
  },
  directives: { draggabletable },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: 'This will permanently erase your information. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const { status } = await this.deleteCard(id);
        if (status !== 200) {
          this.makeToast(false)
          return;
        }

        setTimeout(() => this.$router.go(0), 300)
        this.makeToast(true)
      })
    },
    changePositionToast() {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class="text-danger"
              icon="XCircleIcon"
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class="ml-1 text-danger">Change position failed</h4>
              <div class="ml-1 font-14px">Can not change position</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Delete succeeded!' : 'Delete failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `Restaurant has been deleted.` : 'Can not delete restaurant.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = 'app-restaurant'

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.registerModule(RESTAURANT_APP_STORE_MODULE_NAME, restaurantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME)
    })

    const {
      toast,

      // options
      typeOptions,
      statusOptions,

      // method
      swapPosition,
      fetchCards,
      deleteCard,

      // data
      display,
      restaurants,
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      typeQuery,
      statusQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveCardStatusVariant,
    } = useRestaurantList()

    return {
      toast,

      // options
      typeOptions,
      statusOptions,

      // method
      swapPosition,
      fetchCards,
      deleteCard,

      // data
      display,
      restaurants,
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      typeQuery,
      statusQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveCardStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-label {
  margin: 0 10px 0 0;
}
.add-restaurant-icon {
  margin-left: 8px;
}
.action-button {
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  padding: 5px;
}
.max-w-800px {
  max-width: 800px;
}
.w-150px {
  width: 150px
}
.gap-2 {
  gap: 8px
}
@media (max-width: 768px) {
  .filter {
    flex-direction: column;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}

@media (max-width: 980px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

.card-custom {
  margin-bottom: 0;
  color: #6E6B7B;
}

.card-custom > img {
  border-top-left-radius: 0.428rem;
  border-top-right-radius: 0.428rem; 
}

.card-custom > div {
  padding: 14px;
}

.card-title-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.card-title {
  font-size: 14px;
  color: #5E5873;
  margin-bottom: 0;
}

.card-button-wrapper {
  display: flex;
  gap: 16px;
  margin-top: 24px
}

.card-body-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-badge {
  position: absolute;
  top: 8px;
  right: 14px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
