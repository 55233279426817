import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getRestaurants(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/lineRestaurantList`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRestaurantById(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/lineRestaurantById', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkSlug(_, search) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/slugCheck', { params: { search } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createRestaurant(_, restaurant) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/lineRestaurantCreate', restaurant)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRestaurant(_, restaurant) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/lineRestaurantUpdate', restaurant)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRestaurant(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/lineRestaurantDelete`, { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    swapPosition(_, { oldPosition, newPosition}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/movePosition/lineRestaurant', { old: oldPosition, new: newPosition })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(_, images) {
      return new Promise((resolve, reject) => {
        axios
          .post('/uploader', images, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
